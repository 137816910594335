import { useApi } from "@/use/api/useApi";
import useNotificationsStore from "@/stores/systemNotifications/useNotificationsStore";
import useManualsStore from "@/stores/manuals/useManualsStore";
import type { CommentItemI, CreateCommentI, PushCommentPayload } from "@/use/commenting/CommentsInterface";
import type { Ref } from "vue";
import type { BaseObjectI } from "@/stores/manuals/ManualsInterface";

export function useCommentBlock() {

  const manualsStore = useManualsStore();
  const { showError } = useNotificationsStore();

  function pushNewCommentLocal(comment: CommentItemI, info: PushCommentPayload) {
    if (!info.lot[info.field]?.comments) {
      if (!info.lot[info.field]) {
        // @ts-ignore
        info.lot[info.field] = {
          comments: null,
        }
      }
      // @ts-ignore
      info.lot[info.field].comments = [];
    }
    info.lot[info.field]?.comments?.push(comment);
  }

  function deleteCommentLocal(comments: CommentItemI[], comment: CommentItemI, isDeleted: boolean) {
    if (isDeleted) {
      // если коммент удален
      const index = comments.findIndex(c => c.id === comment.id)
      if (index !== -1) comments.splice(index, 1)
    } else {
      // если при удалении коммента произошла ошибка и его надо вернуть
      comments.push(comment)
    }
  }

  function togglePinCommentLocal(comment: CommentItemI, isPinned: boolean) {
    comment.isPinned = isPinned;
  }

  function getPayload(commentPayload: PushCommentPayload) : CreateCommentI {
    const mode = commentPayload.field === 'tmLot' && (!commentPayload.lot.tmLot || !commentPayload.lot.tmLot?.id) ? 'lot' : commentPayload.field;
    const type = manualsStore.objectTypes.find((e: BaseObjectI) => e.title === mode)?.id;

    return {
      objectId: mode === 'lot' ? commentPayload.lot.lotId : commentPayload.lot[commentPayload.field]!.id,
      objectType: '/api/object-types/' + type,
      content: commentPayload.content,
    }
  }

  function createComment(loader: Ref<boolean>, commentPayload: PushCommentPayload): Promise<any> {

    loader.value = true
    const payload = getPayload(commentPayload)

    return new Promise((resolve, reject) => {
      useApi().comments.postComment<CommentItemI>(payload)
        .then((data) => {
          pushNewCommentLocal(data, commentPayload)
          resolve(null)
        })
        .catch(() => showError('Не удалось сохранить комментарий'))
        .finally(() => loader.value = false)
    })
  }

  function togglePinComment(comment: CommentItemI) {
    const isPinned = comment.isPinned;
    togglePinCommentLocal(comment, !isPinned);

    useApi().comments.patchComment(comment.id, { isPinned: !isPinned })
      .catch(() => {
        showError(comment.isPinned ? 'Не удалось закрепить комментарий' : 'Не удалось открепить комментарий')
        togglePinCommentLocal(comment, !comment.isPinned);
      })
  }

  function removeComment(comments: CommentItemI[], id: number) {
    const comment : CommentItemI | undefined = comments.find(c => c.id === id)
    if (comment) {
      deleteCommentLocal(comments, comment, true)

      useApi().comments.deleteComment(comment.id)
        .catch(() => {
          showError('Не удалось удалить комментарий')
          deleteCommentLocal(comments, comment, false)
        })
    }
  }

  return {
    createComment,
    removeComment,
    togglePinComment,
  }
}
