<template lang="pug">
.form-calculation
  ui-icon.hint-icon(:icon="UiIconNames.Icon_Info")
  span Форма расчета:
  .actions
    .link(@click="download") Скачать форму расчета
    mini-loader(v-if="loader")
    div(v-else style="width: 16px")
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { downloadFormCalculation } from "@/utils/download/fileDownloader";

import UiIcon from "@/components/ui/icon/UiIcon.vue";
import MiniLoader from "@/components/ui/loader/MiniLoader.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "FormCalculationBlock",
  components: {
    UiIcon,
    MiniLoader,
  },
  props: {
    id: {
      type: Number,
      default: -1,
    },
    govRuId: {
      type: String,
      default: '-1',
    },
  },
  setup(props) {

    const loader = ref(false);

    function download() {
      downloadFormCalculation(loader, props.id, props.govRuId)
    }

    return {
      loader,
      download,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/links';

.form-calculation {
  display: flex;
  gap: 8px;

  .hint-icon {
    color: var(--main-red-color);
  }

  .link {
    @include link-mixin;
  }

  .actions {
    display: flex;
    flex-flow: row;
    gap: 8px;

    margin-left: 24px;
  }
}
</style>
