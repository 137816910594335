<template lang="pug">
.string-block__title(:id="`lot-name-${lot?.lotId}`")
  .name-block(v-if="tenderTitle")
    .label Название лота
    .mini-title
      span.lot-title(:class="!showMore && collapse && 'collapsed'" v-html="lotTitle")
      span.show-more(v-if="collapse" @click.stop="showMore = !showMore") {{ !showMore ? 'Показать полностью' : 'Скрыть' }}
  .name-block(v-if="tenderTitle")
    .label Название тендера
    .mini-title
      span.tender-title(:class="!showMoreTender && collapse && 'collapsed'" v-html="tenderTitle")
      span.show-more-tender(v-if="collapse" @click.stop="showMoreTender = !showMoreTender") {{ !showMoreTender ? 'Показать полностью' : 'Скрыть' }}
  .lot-name(v-if="!tenderTitle")
    span.main-lot-title(:class="!showMore && collapse && 'collapsed'" v-html="lotTitle")
    span.show-more(v-if="collapse" @click.stop="showMore = !showMore") {{ !showMore ? 'Показать полностью' : 'Скрыть' }}
</template>

<script lang="ts">
import { defineComponent, nextTick, ref } from 'vue';
import type { PropType } from 'vue';
import type { LotItemInterface } from "~/stores/search/LotItemInterface";

export default defineComponent({
  name: "LotName",
  props: {
    lot: {
      type: Object as PropType<LotItemInterface>,
      default: () => ({}),
    },
    collapse: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {

    const showMore = ref(false);
    const showMoreTender = ref(false);

    const b = '<b>'
    const b1 = '</b>'

    function getTitle(title: string, fts: string) {
      if (fts) {
        const innerFTS = fts.replaceAll(b, '').replaceAll(b1, '')
        if (title === innerFTS) return fts
        else return title.replace(innerFTS, fts)
      } else return title;
    }

    const lotTitle = getTitle(props.lot?.lotTitle, props.lot?.FTS?.lotTitle)
    const tenderTitle = props.lot?.tenderTitle === props.lot?.lotTitle ? undefined : getTitle(props.lot?.tenderTitle, props.lot?.FTS?.tenderTitle)

    function checkHeight(selector: string, link: string, h = 100) {
      try {
        const height = document.getElementById('lot-name-' + props.lot.lotId)?.querySelector(selector)?.scrollHeight;
        // h + 1 - костыль, иначе scrollHeight округляет в большую, и получается не 100, а 101 например
        if (height > h + 2) {
          const showMoreLink = document.getElementById('lot-name-' + props.lot.lotId)?.querySelector(link);
          showMoreLink.style.display = "block";
        }
      } catch {
        console.log('error while checking height of lot number (govRu)', props.lot?.govRuId)
      }
    }

    if (props.collapse) {
      nextTick(() => {
        checkHeight(tenderTitle ? ".lot-title" : ".main-lot-title", ".show-more", tenderTitle ? 90 : 100)
        if (tenderTitle) checkHeight(".tender-title", ".show-more-tender", 90)
      })
    }

    return {
      showMore,
      showMoreTender,
      lotTitle,
      tenderTitle,
    }
  }
})
</script>

<style scoped lang="scss">
@import 'assets/styles/mixin/fonts';
@import 'assets/styles/mixin/links';

.string-block__title {
  display: flex;
  flex-flow: column;
  gap: 12px;

  margin-top: 8px;
  margin-bottom: 16px;

  .main-lot-title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
  }

  .lot-title,
  .tender-title {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }
}

.mini-title {
  display: flex;
  flex-flow: column;
}

.name-block {
  display: grid;
  grid-template-columns: 120px auto;

  .label {
    float: left;
    height: fit-content;
    width: 120px;
    line-height: 18px;
    color: var(--lot-card__color__info-title);
    font-size: var(--lot-card__size__info-title);
  }
}

.lot-name {
  display: flex;
  flex-flow: column;
}

.collapsed {
  @include line-clamp(5);
}

.show-more,
.show-more-tender {
  display: none;

  @include link-mixin;
  font-size: 13px;
  line-height: 20px;
  cursor: pointer;
}
</style>
