import { useSuperAccount } from "~/use/superAccount/useSuperAccount";
import { useApi } from "~/use/api/useApi";
import { getErrorMessage } from "~/use/users/useUserModifier";
import { updateLotCommon } from "~/utils/api/searchRequests";
import useNotificationsStore from "~/stores/systemNotifications/useNotificationsStore";

import type { StatusI } from "~/stores/manuals/ManualsInterface";
import type { SimpleManagerI } from "~/stores/auth/UserInterface";
import type { AppItemInterface, LotItemInterface } from "~/stores/search/LotItemInterface";

export function useAppModifier() {

  const { assignResourceId } = useSuperAccount();

  function createStatusEntity(lot: LotItemInterface, id: number, status: StatusI | null) {
    lot.app = {
      id: id,
      status: status ? status : null,
      comments: [],
    }
  }

  function createNewApp(lot: LotItemInterface, status: StatusI, showNotification = true, justCreate = false) {

    const payload = assignResourceId({
      lot: `/api/lots/${lot.lotId}`,
      status: `/api/apps-statuses/${status.id}`,
    })

    return new Promise((resolve, reject) => {
      useApi().apps.postApp(payload)
        .then((data: any) => {
          if (data) createStatusEntity(lot, data.id, status)
          resolve(lot.lotId)
        })
        .catch((error) => {
          if (error?.response?.status === 412) {
            if (error?.data?.existsObject) createStatusEntity(lot, error?.data?.existsObject?.app?.id, error?.data?.existsObject?.app?.status)
            if (error?.data?.existsObject?.app?.status?.id === status.id) resolve(lot.lotId)
            else {
              if (justCreate) resolve(lot.lotId)
              else {
                useNotificationsStore().showError(`У закупки №${lot.govRuId} уже проставлен ${ error?.data?.existsObject?.app?.status ? 'статус ' + error?.data?.existsObject?.app?.status?.title : 'статус' }, повторите попытку`, 6000)
                reject(lot.lotId)
              }
            }
          } else {
            if (showNotification) useNotificationsStore().showError(getErrorMessage(error?.response?.status, error?.data))
            reject(lot.lotId)
          }
        })
    })
  }

  function updateAppStatus(lot: LotItemInterface, status: StatusI, oldStatus: StatusI, showNotification = true, instant = true) {
    if (instant && lot.app && status) lot.app.status = status;

    return new Promise((resolve, reject) => {
      updateLotCommon(`apps/${lot.app.id}`, { status: "/api/apps-statuses/" + status.id }, showNotification)
        .then((data: any) => {
          if (!instant) lot.app.status = status;
          if (data.responsiblePerson?.id !== lot.app?.responsiblePerson?.id) lot.app.responsiblePerson = data.responsiblePerson;
          if (data.isArchived !== lot.app?.isArchived) lot.app.isArchived = data.isArchived;
          resolve(lot.lotId)
        })
        .catch(() => {
          if (lot.app && oldStatus) lot.app.status = oldStatus;
          reject(lot.lotId)
        })
    })
  }

  function updateAppResponsible(lot: AppItemInterface, manager: SimpleManagerI | null, oldValue: SimpleManagerI | null) {
    lot.app.responsiblePerson = manager ? {
      id: manager.id,
      firstName: manager.firstName,
      middleName: manager.middleName,
      surName: manager.surName,
    } : null;

    updateLotCommon(`apps/${lot.app.id}`, {responsiblePerson: manager ? "/api/users/" + manager.id : null})
      .catch(() => {
        lot.app.responsiblePerson = oldValue ? {
          id: oldValue.id,
          firstName: oldValue.firstName,
          middleName: oldValue.middleName,
          surName: oldValue.surName,
        } : null;
      })
  }

  function updateAppArchived(lot: AppItemInterface) {
    lot.app.isArchived = false;

    updateLotCommon(`apps/${lot.app.id}`, {isArchived: false})
      .catch(() => lot.app.isArchived = true)
  }

  return {
    createNewApp,
    updateAppStatus,
    updateAppResponsible,
    updateAppArchived,
  }
}
