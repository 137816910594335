<template lang="pug">
.string-block-mini
  .more-info
    span.label Заказчик:
    analytic-link.value(v-if="lot?.customers" tab="customers" :company="lot.customers[0]")
    span.unknown(v-else) Заказчик неизвестен

  .more-info
    span.label Тип закупки:
    span.value(v-if="lot?.type?.title") {{ lot?.type?.title }}
    span.unknown(v-else) Вид закупки неизвестен

  .more-info
    span.label Регион закупки:
    span.value(v-if="lot?.region?.title") {{ lot?.region?.title }}
    span.unknown(v-else) Регион закупки неизвестен

  .more-info
    span.label Закон:
    span.value(v-if="lot?.law?.title") {{ lot?.law?.title }}
    span.unknown(v-else) Закон неизвестен

  .more-info
    span.label Площадка:
    a.link.value(v-if="lot?.auctionSite?.link" target="_blank" :href="lot.auctionSite.link") {{ lot.auctionSite.title }}
    span.unknown(v-else) Площадка не известна

  template(v-if="showKeywordEntries")

    .more-info(v-if="lot?.FTS?.docsSearch && lot?.FTS?.docsSearch.includes(b)")
      span.label Документация:
      div.value(v-html="lot.FTS.docsSearch.split('...', 1)[0]")

    .more-info(v-if="lot?.FTS?.purchaseObjects && lot?.FTS?.purchaseObjects.includes(b)")
      span.label Объекты закупки:
      div.value.wrap-anywhere(v-html="lot.FTS.purchaseObjects.split('...', 1)[0]")

    .more-info(v-if="lot?.FTS?.okpdCodes && lot?.FTS?.okpdCodes.includes(b)")
      span.label Коды ОКПД:
      div.value(v-html="lot.FTS.okpdCodes.split('...', 1)[0]")

    .more-info(v-if="lot?.FTS?.deliveryPlace && lot?.FTS?.deliveryPlace.includes(b)")
      span.label Места поставки:
      div.value(v-html="lot.FTS.deliveryPlace.split('...', 1)[0]")

    .keywords(v-if="checkFTS()")
      | Просмотреть все вхождения ключевых слов в документации, кодах окпд и объектах закупки.
      |
      a.link.margin(@click="$emit('showEntryKeywords')") Просмотреть
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import AnalyticLink from "@/components/ui/links/AnalyticLink.vue";
import type { LotItemInterface } from "@/stores/search/LotItemInterface";

export default defineComponent({
  name: "StringBlockMini",
  components: {
    AnalyticLink,
  },
  emits: [
    "showEntryKeywords",
  ],
  props: {
    lot: {
      type: Object as PropType<LotItemInterface>,
      default: () => ({}),
    },
    showKeywordEntries: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {

    const b = '<b>';

    function checkFTS() {
      if (props.lot && props.lot.FTS) {
        return Object.entries(props.lot.FTS).some(([key, value]) => !!value && value.includes('<b>') && (value.split('...', 2).length > 1));
      }
    }

    return {
      b,
      checkFTS,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/links";
@import "@/assets/styles/lots/stringBlockMini";

.link {
  @include link-mixin;
}

.margin {
  margin: 0;
}

.wrap-anywhere {
  overflow-wrap: anywhere;
}
</style>
