<template lang="pug">
.lot-card__cost
  .lot-cost-wrapper
    slot(name="price")
      .item-cost__title Начальная цена
      span.lot-card__main-cost-value
        template(v-if="lot?.cost?.original") {{ costFormatter(lot.cost.original.currency.alpha).format(lot.cost.original.cost) }}
        template(v-else) неизвестно
      .item-cost__title.item-cost_original-price(v-if="lot?.cost?.converted")
        span По текущему курсу:
        span(style="color: var(--main-color-black); margin-left: 8px;") ~ {{ costFormatter(lot.cost.converted.currency.alpha).format(lot.cost.converted.cost) }}

  .lot-card__cost-block-inner
    .lot-cost-wrapper(v-if="type !== 'contract'")
      .item-cost__title Обеспечение заявки
      .item-cost
        .item-cost__value
          template(v-if="lot?.costAppGuarantee?.original") {{ getCostItem(lot.costAppGuarantee.original) }}
          template(v-else) Цена неизвестна
        .item-cost__value._converted(v-if="lot?.costAppGuarantee?.converted?.cost") ({{ getCostItem(lot.costAppGuarantee.converted) }})

    .lot-cost-wrapper
      .item-cost__title Обеспечение исполнения контракта
      .item-cost
        .item-cost__value
          template(v-if="lot?.costContractGuarantee?.original") {{ getCostItem(lot.costContractGuarantee.original) }}
          template(v-else) Цена неизвестна
        .item-cost__value._converted(v-if="lot?.costContractGuarantee?.converted?.cost") ({{ getCostItem(lot.costContractGuarantee.converted) }})

    .lot-cost-wrapper(v-if="type === 'contract'")
      .item-cost__title Обеспечение гарантийных обязательств
      .item-cost
        .item-cost__value
          template(v-if="lot?.costPostContractGuarantee?.original") {{ getCostItem(lot.costPostContractGuarantee.original) }}
          template(v-else) Цена неизвестна
        .item-cost__value._converted(v-if="lot?.costPostContractGuarantee?.converted?.cost") ({{ getCostItem(lot.costPostContractGuarantee.converted) }})

</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { costFormatter } from "~/utils/formatter/costFormatter";
import type { CostItemI, FullCostI } from "~/stores/manuals/ManualsInterface";
import type { LotItemInterface } from "~/stores/search/LotItemInterface";

export default defineComponent({
  name: "LotCost",
  props: {
    lot: {
      type: Object as PropType<LotItemInterface>,
      default: () => ({}),
    },
    type: {
      type: String as PropType<'tmLot'|'app'|'contract'>,
      default: 'tmLot',
    },
  },
  methods: {
    costFormatter,
  },
  setup() {

    function getCost(cost: FullCostI) {
      return cost.converted
        ? costFormatter(cost.converted.currency.alpha).format(cost.converted.cost)
        : costFormatter(cost.original.currency.alpha).format(cost.original.cost)
    }

    function getCostItem(c: CostItemI) {
      return costFormatter(c.currency.alpha).format(c.cost)
    }

    return {
      getCost,
      getCostItem,
    }
  }
})
</script>

<style scoped lang="scss">
@import "assets/styles/lots/lotCardCost";
</style>
