import { useApi } from "~/use/api/useApi";
import { getErrorMessage } from "~/use/users/useUserModifier";
import type { LotItemInterface, TenderTagI, TmLotAppInterface } from "~/stores/search/LotItemInterface";
import useNotificationsStore from "~/stores/systemNotifications/useNotificationsStore";

function getTagFromPostResponse(response: any) {
  return {
    id: response?.tag?.id,
    color: response?.tag?.color,
    title: response?.tag?.title,
    tmLotTagId: response?.id,
  }
}

interface ResponseErrorI {
  id: number,    // tmLot.id
  app: TmLotAppInterface | null,
  tags: TenderTagI[],
}

interface ResponseSuccessI {
  id: number,         // tmLotTagId
  tag: TenderTagI,    // without tmLotTagId
  tmLot: {
    id: number,
  },
}

export function useLotTags(withNotification = true) {

  const { showError, showWarning } = useNotificationsStore();

  function onResolve(lot: LotItemInterface, response: ResponseSuccessI) {
    if (!lot.tmLot) lot.tmLot = {}
    if (!lot?.tmLot?.tags) lot.tmLot.tags = []
    if (!lot?.tmLot?.id) lot.tmLot.id = response?.tmLot?.id

    if (!lot.tmLot.tags.find(e => e.id === response?.tag?.id)) lot.tmLot.tags.push(getTagFromPostResponse(response))
  }

  // function onReject(lot: LotItemInterface, response: ResponseErrorI) {
  //   let hasChanges = false
  //
  //   if (!lot.tmLot) lot.tmLot = {}
  //   if (!lot?.tmLot?.id) lot.tmLot.id = response?.id
  //   if (!lot?.tmLot?.tags) {
  //     hasChanges = true
  //     lot.tmLot.tags = response?.tags || []
  //   }
  //
  //   if (!lot?.app && response?.app) {
  //     hasChanges = true
  //     lot.app = response.app
  //   }
  //
  //   if (hasChanges) showWarning(`Лот №${ lot.govRuId } был обновлен`)
  // }

  function applyTenderTag(lot: LotItemInterface, id: number) {

    // если есть tmLotId, отправляем его, если нет, то lotId который есть всегда
    const payload = {
      tag: `/api/tags/${id}`,
      tmLot: lot?.tmLot?.id ? `/api/active_lots/${ lot.tmLot.id }` : undefined,
      lot: !lot?.tmLot?.id ? `/api/lots/${ lot?.lotId }` : undefined,
    }

    return new Promise((resolve, reject) => {

      useApi().tags.applyTenderTagToLot(payload)
        .then((response: any) => {
          onResolve(lot, response)
          resolve(lot.lotId)
        })
        .catch((error) => {
          // if (error?.response?.status === 412 && error?.data?.existsObject?.tags?.find(e => e.id === id)) {
          //   onReject(lot, error.data.existsObject)
          //   resolve(lot.lotId)
          // } else {
          // if (withNotification) showError(getErrorMessage(error?.response?.status, null || error?.data, 'Не удалось назначить тег на лот'))
          if (withNotification) showError(getErrorMessage(error?.response?.status, '', 'Не удалось назначить тег на лот'))
          reject(lot.lotId)
          // }
        })
    })
  }

  function removeTenderTag(lot: LotItemInterface, tag: TenderTagI) {

    const onRemove = () => {
      lot.tmLot.tags = lot.tmLot.tags.filter(e => e.id !== tag.id)
    }

    return new Promise((resolve, reject) => {

      useApi().tags.removeTenderTagFromLot(tag.tmLotTagId)
        .then(() => {
          onRemove()
          resolve(null)
        })
        .catch((error) => {
          if (error?.response?.status === 404) {
            // считаем, что кто-то уже удалил тег
            onRemove()
            resolve(null)
          } else {
            if (withNotification) showError(getErrorMessage(error?.response?.status, error?.data, 'Не удалось снять тег с лота'))
            reject()
          }
        })
    })
  }

  return {
    applyTenderTag,
    removeTenderTag,
  }
}
