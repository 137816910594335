<template lang="pug">
ui-dropdown
  ui-icon(:icon="fromActionPanel ? UiIconNames.Chevron_ArrowDown : UiIconNames.Icon_Tag" :size="14" clickable :class="fromActionPanel && 'blue'")
  template(#dropdown="{ close }")
    .tags-list
      .tag-wrapper(v-for="tag of tags" @click.stop="selectTag(tag, close)")
        lot-tag(:tag="tag" size="mini")
        .checked
          mini-loader(v-if="loaders.includes(tag.id)" :size="10")
          ui-icon(v-else-if="selectedTags.map(e => e.id).includes(tag.id)" :icon="UiIconNames.Icon_CheckUnderline" :size="10")
      span.info-message(v-if="!fromActionPanel && tags.length") Кликните на тег для того, чтобы назначить или снять его
      template(v-else-if="!fromActionPanel")
        span.empty-tags У вас нет созданных тегов
        span.info-message Для создания тега нажмите на кнопку "управление тегами" в шапке сайта или перейдите в настройки
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import { storeToRefs } from "pinia";
import { useLotTags } from "~/components/tags/useLotTags";

import UiIcon from "~/components/ui/icon/UiIcon.vue";
import MiniLoader from "~/components/ui/loader/MiniLoader.vue";
import UiDropdown from "~/components/ui/dropdown/UiDropdown.vue";
import LotTag from "~/components/tags/LotTag.vue";

import UiIconNames from "~/components/ui/icon/UiIconNames";
import useSearchStore from "~/stores/search/useSearchStore";
import type { TenderTagI } from "~/stores/search/LotItemInterface";

export default defineComponent({
  name: "TagsSelector",
  components: {
    LotTag,
    UiDropdown,
    MiniLoader,
    UiIcon,
  },
  emits: [
    'on:select',
  ],
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    fromActionPanel: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {

    const loaders = ref<number[]>([]);

    const searchStore = useSearchStore();
    const { tags } = storeToRefs(searchStore);

    const { applyTenderTag, removeTenderTag } = useLotTags();

    const selectedTags = computed<TenderTagI[]>(() => props.modelValue?.tmLot?.tags || [])

    function selectTag(tag: TenderTagI, close?: Function) {
      if (props.fromActionPanel) {
        context.emit('on:select', tag)
        if (close) close()
        return
      }

      loaders.value.push(tag.id)

      const t = selectedTags.value.find(e => e.id === tag.id) // не удалять, тут мы ищем тег с конкретного лота, а не из списка тегов
      if (t) removeTenderTag(props.modelValue, t).catch(() => {}).finally(() => loaders.value = loaders.value.filter(e => e !== tag.id))
      else applyTenderTag(props.modelValue, tag.id).catch(() => {}).finally(() => loaders.value = loaders.value.filter(e => e !== tag.id))
    }

    return {
      tags,
      loaders,
      selectedTags,
      selectTag,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
.tags-list {
  display: flex;
  flex-flow: column;
  padding: 6px 4px;
  width: 240px;

  max-height: 340px;
  overflow: auto;
}

.tag-wrapper {
  display: flex;
  flex-flow: row;
  gap: 8px;
  padding: 4px 16px;
  cursor: pointer;

  &:hover {
    background-color: #fbfbfb;
  }

  .checked {
    display: flex;
    margin-left: auto;
    color: var(--default-blue-color);
    align-items: center;
  }
}

.blue {
  color: var(--main-color-blue);
}

.empty-tags {
  padding: 8px 16px;
  text-align: center;
}

.info-message {
  padding: 8px 12px;
  color: var(--main-placeholder-color);
  line-height: 14px;
  text-align: center;
}
</style>
