<template lang="pug">
.ui-dropdown(ref="reference")
  .reference(@click="onReferenceClick")
    slot
  .floating(v-if="visible && !disabled" ref="floating" :style="floatingStyles")
    span.arrow
    slot(name="dropdown" :close="toggle")
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useToggle } from "@/use/other/useToggle";
import { useFloating, shift, autoUpdate } from '@floating-ui/vue';
import { onClickOutside } from '@vueuse/core'

export default defineComponent({
  name: "UiDropdown",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {

    const reference = ref(null);
    const floating = ref(null);

    const { visible, hide, toggle } = useToggle();

    const { floatingStyles }
      = useFloating(reference, floating, { placement: 'bottom', middleware: [shift()], whileElementsMounted: autoUpdate });

    onClickOutside(floating, hide, { ignore: [reference] });

    function onReferenceClick() {
      if (!props.disabled) toggle()
    }

    return {
      reference,
      floating,
      visible,
      floatingStyles,
      toggle,
      onReferenceClick,
    }
  }
})
</script>

<style scoped lang="scss">
.ui-dropdown {
  display: flex;
}

.reference {
  display: flex;
  user-select: none;
}

.floating {
  background-color: white;
  font-size: 12px;
  line-height: 20px;
  position: relative;

  box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
  border: 1px solid #e4e7ed;
  border-radius: 4px;

  z-index: 2000;
  min-width: 10px;
  overflow-wrap: break-word;
  margin-top: 8px;

  .arrow {
    position: absolute;
    top: -5px;
    left: calc(50% - 5px);
    width: 10px;
    height: 10px;
    z-index: -1;

    &:before {
      border: 1px solid #e4e7ed;
      background-color: white;
      right: 0;
      border-bottom-color: transparent !important;
      border-right-color: transparent !important;
      border-top-left-radius: 2px;

      position: absolute;
      width: 10px;
      height: 10px;
      z-index: -1;
      content: " ";
      transform: rotate(45deg);
      box-sizing: border-box;
    }
  }
}
</style>
