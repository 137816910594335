<template lang="pug">
old-tooltip(:icon="UiIconNames.Icon_Info" type="info" tooltip-position="bottom-start")
  template(#content)
    div(style="max-width: 320px;")
      div(v-html="string")
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import type { BaseObjectI } from "@/stores/manuals/ManualsInterface";

import OldTooltip from "~/components/ui/tooltip/OldTooltip.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "TemplateInfo",
  components: {
    OldTooltip,
  },
  props: {
    templates: {
      type: Array as PropType<BaseObjectI[]>,
      default: () => ([]),
    }
  },
  setup(props) {

    const string =
        props.templates && props.templates.length > 0
            ? (`<div>Входит в шаблоны: <ol style="margin: 0;"> ${ props.templates?.map(template => { return "<li>" + template.title + "</li>" }).join('') } </ol></div>`)
            : '<span>Закупка не принадлежит ни одному шаблону</span>';

    return {
      string,
      UiIconNames,
    };
  },
});
</script>

<style scoped lang="scss">
</style>
