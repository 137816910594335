<template lang="pug">
.comments-block
  .comments-list(ref="commentsList")
    .comment-block__item(
      v-for="comment in lot?.[field]?.comments"
      :key="comment.dateCreate"
      :class="(readonly || (!roleAdmin && comment.user.id !== me.id)) && 'readonly'"
    )
      .item__sender-icon {{ comment.user.surName.slice(0, 1) }}
      .item__sender-label {{ comment.user.surName}} {{ comment.user.firstName }}, {{ formatDateTime(comment.dateCreate) }}
      .item__content {{ comment.content }}
      .item__actions(v-if="!readonly && (comment.user.id === me.id || roleAdmin)")
        ui-icon(
          clickable
          :class="comment.isPinned && 'pinned'"
          :icon="comment.isPinned ? UiIconNames.Icon_Star : UiIconNames.Icon_StarO"
          @click="togglePinComment(comment)"
        )
        ui-icon(
          clickable
          :icon="UiIconNames.Icon_DeleteSmoothed"
          @click="cutComment(comment)"
        )

  .comments-block__input
    base-input(
      v-model="commentContent"
      placeholder="Написать комментарий"
      style="width: 100%"
      :disabled="readonly"
      @keyup.enter="pushComment"
    )
    ui-icon.comments-block__input-send(
      v-if="!pushCommentLoader"
      :clickable="!readonly"
      :size="22"
      :icon="UiIconNames.Icon_Send"
      :class="!commentContent && '_disabled'"
      @click="pushComment"
    )
    mini-loader(v-else)
</template>

<script lang="ts">
import type { PropType } from "vue";
import type { CommentItemI, CommentEntityBlock, PushCommentPayload } from "@/use/commenting/CommentsInterface";
import type { LotItemInterface } from "@/stores/search/LotItemInterface";
import { formatDateTime } from "@/utils/formatter/dateFormatter";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { useCommentBlock } from "@/use/commenting/useCommentBlock";
import { defineComponent, nextTick, ref } from "vue";
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import BaseInput from "@/components/ui/base/BaseInput.vue";
import MiniLoader from "@/components/ui/loader/MiniLoader.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "LotComments",
  components: {
    UiIcon,
    BaseInput,
    MiniLoader,
  },
  props: {
    lot: {
      type: Object as PropType<LotItemInterface>,
      default: () => ({}),
    },
    field: {
      type: String as PropType<CommentEntityBlock>,
      default: 'tmLot',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {

    const { me, roleAdmin } = useUserAccess();

    const {
      createComment,
      removeComment,
      togglePinComment,
    } = useCommentBlock();

    const commentContent = ref("");
    const commentsList = ref(null);
    const pushCommentLoader = ref(false);

    function scrollToBottom() {
      nextTick(() => {
        if (commentsList.value) commentsList.value.scrollTop = commentsList.value.scrollHeight;
      });
    }

    scrollToBottom();

    function pushComment() {
      if (commentContent.value && !props.readonly) {
        const payload : PushCommentPayload = { lot: props.lot, field: props.field, content: commentContent.value };
        createComment(pushCommentLoader, payload)
          .then(() => {
            commentContent.value = "";
            scrollToBottom();
          })
      }
    }

    function cutComment(comment: CommentItemI) {
      removeComment(props.lot?.[props.field]?.comments || [], comment.id)
    }

    return {
      me,
      roleAdmin,
      pushCommentLoader,
      commentsList,
      commentContent,
      formatDateTime,
      pushComment,
      cutComment,
      togglePinComment,
      UiIconNames,
    }
  }
});
</script>

<style scoped lang="scss">
.comments-block {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .comments-list {
    overflow: auto;
    word-wrap: break-word;
    min-height: 120px;
    max-height: 130px;
    display: flex;
    flex-flow: column;
    gap: 8px;
  }

  .comment-block__item {
    display: grid;
    grid-template-areas:
      "item__sender-icon    item__sender-label    item__actions"
      "item__sender-icon    item__content         item__actions";
    grid-template-rows: auto auto;
    grid-template-columns: 26px auto 40px;
    grid-gap: 4px;
    margin-right: 4px;

    &:first-child {
      margin-top: auto;
    }

    &.readonly {
      grid-template-areas:
      "item__sender-icon    item__sender-label"
      "item__sender-icon    item__content";
      grid-template-columns: 26px auto;
    }

    .item__sender-icon {
      font-size: 12px;
      font-weight: bold;
      height: 26px;
      box-sizing: border-box;
      border-radius: 50px;
      border: 2px solid #e0e0e0;
      grid-area: item__sender-icon;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .item__sender-label {
      font-size: 10px;
      line-height: 14px;
      grid-area: item__sender-label;
      color: #8C8C8C;
    }

    .item__content {
      font-size: 13px;
      line-height: 18px;
      grid-area: item__content;
    }

    .item__actions {
      grid-area: item__actions;
      display: flex;
      flex-flow: row;
      gap: 4px;
      padding: 0 2px;

      .pinned {
        color: var(--main-warning-color);
      }
    }
  }

  .comments-block__input {
    margin-top: 10px;
    display: grid;
    grid-template-columns: auto 22px;
    column-gap: 8px;
    align-items: center;

    .comments-block__input-send {
      &._disabled {
        color: var(--main-placeholder-color);
        cursor: default;
      }
    }
  }
}
</style>
